<template>
  <div class="container mx-auto">
    <!-- confirmation modal to delete temporary notes -->
    <app-modal :show="isConfirming" @close="isConfirming = false">
      <template #title>
        <span>
          {{ $t("invoice.report.delete_temp_notes") }}
        </span>
      </template>
      <template #body>
        {{ $t("invoice.report.delete_temp_notes_hint") }}
      </template>
      <template #action>
        <app-button :showf70Icon="false" width="ml-3" @click="deleteTempNotes">
          {{ $t("invoice.report.delete_temp_notes") }}
        </app-button>
      </template>
    </app-modal>
    <!-- page title and action buttons -->
    <div class="px-4 border-b p-2 mt-5">
      <div
        class="
          px-5
          py-2
          flex
          m-auto
          lg:px-0
          container
          items-center
          justify-between
        "
      >
        <div>
          <span class="text-xl">
            {{ $t("invoice.report.aging_report_title_short") }}
          </span>
        </div>
        <div class="flex flex-row space-x-3">
          <app-button
            width="w-1/8"
            :showf70Icon="false"
            @click="displayTempNotesForm"
          >
            <template v-slot:icon>
              <app-icon-outline
                name="PlusIcon"
                class="h-5 w-5 text-white mr-2"
              />
            </template>
            {{ $t("invoice.action.add_temporary_notes") }}
          </app-button>
          <app-button-dropdown @click.stop>
            <app-button-outline>
              {{ $t("invoice.action.export_as") }}
            </app-button-outline>
            <template #menu-items>
              <app-button-dropdown-item
                v-for="(label, mode) in exportTargets"
                :key="mode"
                class="p-3"
                @click.stop="exportAs(mode)"
              >
                {{ label }}
              </app-button-dropdown-item>
            </template>
          </app-button-dropdown>
        </div>
      </div>
    </div>
    <!-- report title -->
    <div class="flex flex-col items-center my-10">
      <p class="text-gray-600 text-lg">{{ business.name }}</p>
      <p class="text-gray-600 text-3xl font-semibold">
        {{ $t("invoice.report.aging_report_title") }}
      </p>
      <p class="text-gray-400 text-sm">
        {{
          $t("invoice.report.as_of", { time: $moment().format("MMMM YYYY") })
        }}
      </p>
    </div>
    <!-- report period -->
    <div class="flex flex-col space-y-3">
      <div class="flex flex-row justify-between">
        <p class="text-gray-500 text-lg font-semibold">
          {{ $t("invoice.report.summary", { currency }) }}
        </p>
        <p class="text-red-600 text-lg font-semibold">
          {{
            $t("invoice.report.total_outstanding", {
              outstanding: $formats.currency(
                currency,
                summary.total.outstanding
              ),
            })
          }}
        </p>
      </div>
      <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-x-3">
        <div
          v-for="({ from, to }, key) in availablePeriods"
          :key="key"
          class="flex flex-col rounded space-y-3 cursor-pointer px-6 py-3"
          :class="[period.key === key ? 'border-primary border-2' : 'border']"
          @click="changePeriod(key)"
        >
          <p class="text-gray-600 text-slightly-sm font-semibold">
            {{
              $t(`invoice.report.${to ? "within" : "over"}_period`, {
                from,
                to,
              })
            }}
          </p>
          <p class="text-gray-600 text-2xl font-semibold">
            {{ $formats.currency(currency, summary[key].outstanding) }}
          </p>
          <p class="text-gray-400 text-slightly-sm">
            {{ $t("invoice.report.n_outstanding", { n: summary[key].n }) }}
          </p>
        </div>
      </div>
    </div>
    <!-- invoices table -->
    <div class="mt-16">
      <p v-if="!loading" class="text-gray-500 text-sm -mb-5">
        {{
          $t(`invoice.report.list_summary_${period.to ? "within" : "over"}`, {
            ...period,
            n: apiPaginationResponse.data.to,
            total: apiPaginationResponse.data.total,
          })
        }}
      </p>
      <app-table
        :apiResponse="apiPaginationResponse.data"
        :loading="apiPaginationLoading"
        :showEmptyButton="false"
      >
        <template v-slot:header>
          <th class="font-bold">{{ $t("invoice.table_header.no") }}</th>
          <th class="font-bold">
            {{ $t("invoice.table_header.customer_name") }}
          </th>
          <th class="font-bold">
            {{ $t("invoice.table_header.due_date_full") }}
          </th>
          <th class="font-bold text-center">
            {{ $t("invoice.table_header.aging") }}
          </th>
          <th class="font-bold">
            {{ $t("invoice.table_header.due_amount_short") }}
          </th>
        </template>
        <template v-slot:body="data">
          <td>{{ data.model.invoice_no }}</td>
          <td>{{ data.model.customer.name }}</td>
          <td>
            {{ $moment(data.model.payment_term_date).format("DD/MM/YYYY ") }}
          </td>
          <td class="text-center">{{ calculateAge(data.model) }}</td>
          <td>
            <p class="font-medium">
              {{
                $formats.currency(
                  data.model.currency,
                  data.model.total_amount ?? 0.0
                )
              }}
            </p>
          </td>
        </template>
      </app-table>
    </div>
    <!-- temporary notes -->
    <div ref="tempNotes" class="w-full md:w-1/2 lg:w-1/3 mb-3">
      <div v-if="isEditingTempNotes" class="mb-10">
        <form
          @submit.prevent="saveTempNotes"
          class="flex flex-col items-start space-y-2"
        >
          <app-form-textarea
            name="tempNotes.content"
            ref="tempNotesContent"
            type="text"
            class="w-full"
            v-model="tempNotesContent"
            :labelText="$t('invoice.report.temp_notes')"
            :placeholder="$t('invoice.report.temp_notes_placeholder')"
            :errorMessage="errors.content"
          />
          <div class="flex justify-end w-full gap-2">
            <app-button
              :showf70Icon="false"
              :primary="false"
              :loading="apiPaginationLoading"
              width=""
              class="text-gray-500"
              @click="resetTempNotes"
            >
              {{ $t("general.cancel") }}
            </app-button>
            <app-button
              :showf70Icon="false"
              type="submit"
              :loading="apiPaginationLoading"
              width=""
              :disabled="!tempNotesContent"
              @click="saveTempNotes"
            >
              {{ $t("general.save") }}
            </app-button>
          </div>
        </form>
      </div>
      <div v-else-if="tempNotes.created_at" class="space-y-2">
        <div class="w-full h-full space-y-2">
          <label class="block text-sm font-light text-gray-700">
            {{ $t("invoice.report.temp_notes") }}
          </label>
          <div class="border rounded-md h-24 px-3 py-3 text-sm">
            {{ tempNotes.content }}
          </div>
        </div>
        <div class="flex justify-end space-x-4 text-slightly-sm text-gray-500">
          <p class="cursor-pointer" @click="isConfirming = true">
            {{ $t("general.delete") }}
          </p>
          <p class="cursor-pointer" @click="displayTempNotesForm">
            {{ $t("general.edit") }}
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      isEditingTempNotes: false,
      isConfirming: false,
      exportTargets: {
        sel_pdf: this.$t("invoice.action.export_selection_as", {
          format: "PDF",
        }),
        sel_csv: this.$t("invoice.action.export_selection_as", {
          format: "CSV",
        }),
        all_pdf: this.$t("invoice.action.export_all_as", { format: "PDF" }),
        all_csv: this.$t("invoice.action.export_all_as", { format: "CSV" }),
      },
      period: null,
      availablePeriods: {
        _1_30: { from: 1, to: 30 },
        _31_60: { from: 31, to: 60 },
        _61_90: { from: 61, to: 90 },
        _91: { from: 91 },
      },
      tempNotesContent: "",
      // @TODO: move this to computed and use store instead
      tempNotes: { content: "", created_at: null },
    };
  },

  mounted() {
    this.$store.dispatch("businessStore/getBusinessFromSelectedRoleBusinessId");
    this.changePeriod("_1_30");
  },

  computed: {
    business() {
      return this.$store.getters["businessStore/business"];
    },
    currency() {
      // @TODO: should have configuration for default currency
      return "MYR";
    },
    summary() {
      // @TODO: will be retrieved from store, service, and repository
      return {
        total: { outstanding: 18000, n: 46 },
        _1_30: { outstanding: 3000, n: 10 },
        _31_60: { outstanding: 4000, n: 11 },
        _61_90: { outstanding: 5000, n: 12 },
        _91: { outstanding: 6000, n: 13 },
      };
    },
    apiPaginationResponse() {
      return this.$store.getters["invoiceStore/apiPaginationResponse"];
    },
    errors() {
      return this.$store.getters["invoiceStore/errors"] ?? {};
    },
    apiPaginationLoading() {
      return this.$store.getters["invoiceStore/apiPaginationLoading"];
    },
  },

  methods: {
    displayTempNotesForm() {
      this.tempNotesContent = this.tempNotes?.content || "";
      this.isEditingTempNotes = true;
      setTimeout(() => {
        window.scrollTo(0, this.$refs.tempNotes.offsetTop + 200);
        this.$refs.tempNotesContent.$refs.input.focus();
      }, 100);
    },
    resetTempNotes() {
      this.tempNotesContent = "";
      this.isEditingTempNotes = false;
    },
    saveTempNotes() {
      this.tempNotes.content = this.tempNotesContent;
      this.tempNotes.created_at = new Date();
      console.log("@TODO: saving notes via store", this.tempNotes);
      this.resetTempNotes();
    },
    deleteTempNotes() {
      this.isConfirming = false;
      console.log("@TODO: deleting notes via store");
      this.tempNotes = { content: "", created_at: null };
      this.resetTempNotes();
    },
    exportAs(mode) {
      console.log(`Exporting with mode: ${mode}`);
    },
    changePeriod(key) {
      this.period = {
        key,
        ...this.availablePeriods[key],
      };
      const filters = {};
      this.$store.dispatch("invoiceStore/retrieveInvoices", filters);
    },
    calculateAge(invoice) {
      return Math.floor(Math.random() * 30);
    },
  },
};
</script>